<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="Tambah Prepaid" :active="isActive" v-on:update:active="emitModalIsActive">
      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-2/12 w-full">
            <label class="ml-1 text-xs">No. Prepaid *</label>
            <vs-input class="w-full" v-model="data.no_prepaid" placeholder="Generate Otomatis" disabled/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Proyek *</label>
            <vs-select class="w-full" v-model="data.id_proyek">
              <vs-select-item v-for="(it, index) in proyeks" :key="index" :value="it.id" :text="`${it.kode} - ${it.nama}`"/>
            </vs-select>
          </div>
          <div class="vx-col sm:w-2/12 w-full">
            <label class="ml-1 text-xs">Date *</label>
            <flat-pickr class="w-full" v-model="data.tgl_prepaid"></flat-pickr>
          </div>
          <div class="vx-col sm:w-2/12 w-full">
            <label class="ml-1 text-xs">Total Count *</label>
            <vs-input class="w-full" v-model="data.total_count" type="number"/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="mr-1 text-xs whitespace-no-wrap">Start From *</label>
            <div class="flex items-center">
              <vx-input-group>
                <template slot="prepend">
                  <vx-input-group>
                    <vs-select v-model="data.start_bulan" class="w-full">
                      <vs-select-item v-for="(it, index) in [
                        {bulan: 1, nama: 'January'},
                        {bulan: 2, nama: 'February'},
                        {bulan: 3, nama: 'March'},
                        {bulan: 4, nama: 'April'},
                        {bulan: 5, nama: 'May'},
                        {bulan: 6, nama: 'June'},
                        {bulan: 7, nama: 'July'},
                        {bulan: 8, nama: 'August'},
                        {bulan: 9, nama: 'September'},
                        {bulan: 10, nama: 'October'},
                        {bulan: 11, nama: 'November'},
                        {bulan: 12, nama: 'December'}
                      ]" :key="index" :value="it.bulan" :text="it.nama"/>
                    </vs-select>
                  </vx-input-group>
                  <div class="text-xs px-1 rounded-none d-theme-border-grey-light">
                    <span class="p-0"></span>
                  </div>
                  <vx-input-group>
                    <vs-input class="w-full" v-model="data.start_tahun" placeholder="Year" type="number"/>
                  </vx-input-group>
                </template>
              </vx-input-group>
            </div>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Description *</label>
            <vs-input class="w-full" v-model="data.keterangan"/>
          </div>
        </div>

        <vs-divider>Detail</vs-divider>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="flex flex-wrap justify-between items-center mb-1">
              <div class="mb-4 md:mb-0 mr-4">
                <div class="flex">
                  <vs-button color="success" icon-pack="feather" icon="icon-plus" @click="addRow" class="py-2 px-3 mr-2">Add</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="overflow-y-auto" style="height: 25rem">
          <vs-table :data="data.details" stripe>
            <template slot="thead">
              <vs-th class="whitespace-no-wrap">#</vs-th>
              <vs-th class="whitespace-no-wrap">Account *</vs-th>
              <vs-th class="whitespace-no-wrap">Description *</vs-th>
              <vs-th class="whitespace-no-wrap">Debit</vs-th>
              <vs-th class="whitespace-no-wrap">Credit</vs-th>
            </template>
            <template slot-scope="{data}">
              <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
                <vs-td>
                  <vs-button color="danger" class="py-2 px-3" type="filled" icon-pack="feather" icon="icon-x" size="small" @click="removeRow(item.uuid)"/>
                </vs-td>
                <vs-td>
                  <div class="w-80">
                    <div @click="chooseCoa(item.uuid)">
                      <vx-input-group>
                        <vs-input placeholder="Account" :value="item.nama_coa" readonly/>
                        <template slot="append">
                          <div class="append-text btn-addon">
                            <vs-button type="filled" icon-pack="feather" icon="icon-search"/>
                          </div>
                        </template>
                      </vx-input-group>
                    </div>
                  </div>
                </vs-td>
                <vs-td><vs-input class="w-full" type="text" v-model="item.keterangan"/></vs-td>
                <vs-td><v-money class="w-full text-right" type="text" v-model="item.jml_debet"/></vs-td>
                <vs-td><v-money class="w-full text-right" type="text" v-model="item.jml_kredit"/></vs-td>
              </vs-tr>
              <!--footer-->
              <vs-tr v-if="data.length > 0">
                <vs-td></vs-td>
                <vs-td></vs-td>
                <vs-td></vs-td>
                <vs-td class="left"><v-money class="w-38 text-right" type="text" :value="grandTotalDebet" disabled/></vs-td>
                <vs-td class="left"><v-money class="w-38 text-right" type="text" :value="grandTotalKredit" disabled/></vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
        <div class="vx-row mt-6">
          <div class="vx-col w-full">
            <div class="flex justify-end w-full">
              <div class="flex">
                <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
                <vs-button :disabled="isLoading" @click="save">
                  <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
                  <span v-if="!isLoading">Simpan</span>
                </vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--modal coa-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding"
                title="Pilih COA"
                :active="modalCoa.active"
                v-on:update:active="modalCoa.active = $event">
        <Coa :selectable="true" :externalFilter="filterCoa" @selected="onSelectedModalCoa"/>
      </vs-popup>

    </vs-popup>
  </div>
</template>

<script>
import SetupPrepaidRepository from '@/repositories/accounting/setup-prepaid-repository'
import ProyekRepository from '@/repositories/master/proyek-repository'
import Coa from '@/views/pages/master/coa/Coa'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import VMoney from '@/views/components/v-money/VMoney'
import _ from 'lodash'
import moment from 'moment'
import { v4 as uuid } from 'uuid'

export default {
  name: 'SetupPrepaidAdd',
  props: ['isActive'],
  components: {
    ValidationErrors,
    flatPickr,
    Coa,
    VMoney
  },
  mounted () {
    this.getAllProyek()
  },
  data () {
    return {
      isLoading: false,
      errors: null,
      proyeks: [],
      modalCoa: {
        rowUuid: null,
        active: false
      },
      data: {
        id_proyek: null,
        total_count: null,
        tgl_prepaid: moment().format('YYYY-MM-DD'),
        start_bulan: moment().format('M'),
        start_tahun: moment().format('YYYY'),
        details: []
      }
    }
  },
  computed: {
    grandTotalDebet () {
      return _.sumBy(this.data.details, item => item.jml_debet)
    },
    grandTotalKredit () {
      return _.sumBy(this.data.details, item => item.jml_kredit)
    },
    filterCoa () {
      return {
        tipe: 'DETAIL',
        id_proyek: this.data.id_proyek
      }
    }
  },
  methods: {
    getAllProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    save () {
      this.errors = null
      this.isLoading = true

      const params = this.buildParams()
      SetupPrepaidRepository.create(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    buildParams () {
      return {
        id_proyek: this.data.id_proyek,
        tgl_prepaid: this.data.tgl_prepaid,
        total_count: this.data.total_count,
        start_bulan: this.data.start_bulan,
        start_tahun: this.data.start_tahun,
        keterangan: this.data.keterangan,
        details: _.map(this.data.details, item => _.pick(item, ['id_coa', 'jml_debet', 'jml_kredit', 'keterangan']))
      }
    },

    chooseCoa (rowUuid, type) {
      this.modalCoa.type = type
      this.modalCoa.rowUuid = rowUuid
      this.modalCoa.active = true
    },

    onSelectedModalCoa (data) {
      const uuid = this.modalCoa.rowUuid
      const index = _.findIndex(this.data.details, item => item.uuid === uuid)
      this.data.details[index].id_coa = data.id
      this.data.details[index].nama_coa = data.kode + ' - ' + data.nama
      this.modalCoa.active = false
    },

    addRow () {
      const row = {
        uuid: uuid(),
        jml_debet: 0,
        jml_kredit: 0
      }
      this.data.details.push(row)
    },

    removeRow (uuid) {
      const index = _.findIndex(this.data.details, item => item.uuid === uuid)
      this.data.details.splice(index, 1)
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['proyeks'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
